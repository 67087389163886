import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import HomePage from './pages/HomePage/HomePage';
import QuestionPage from './pages/QuestionPage/QuestionPage';
import ApplyPage from './pages/ApplyPage/ApplyPage';

const App = () => {
	return (
		<Router>
			<div id='App_container'>
				<Switch>
					<Route exact path='/' component={HomePage} />
					<Route exact path='/question' component={QuestionPage} />
					<Route exact path='/apply' component={ApplyPage} />
				</Switch>
			</div>
		</Router>
	);
};

export default App;
