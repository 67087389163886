import React, { useState } from 'react';

const QuestionPage = () => {
	const [title, setTitle] = useState('');
	const [contact, setContact] = useState('');
	const [text, setText] = useState('');

	return (
		<div className='question_wrap'>
			<div className='question'>
				<h2>{`< 문의 >`}</h2>
				<p>
					{`작업에 필요하신 필요 인력 등 문의 주시면\n빠른 시간 내에 연락드리겠습니다.`}
				</p>
				<ul>
					<li>
						<p>제목</p>
						<input
							placeholder='제목을 입력해주세요.'
							defaultValue={title}
							onChange={(e) => {
								setTitle(e.target.value);
							}}
						/>
					</li>
					<li>
						<p>연락처</p>
						<input
							placeholder='적어주신 연락처로 연락드리겠습니다.'
							defaultValue={contact}
							onChange={(e) => {
								setContact(e.target.value);
							}}
						/>
					</li>
					<li>
						<p>내용</p>
						<textarea
							placeholder='작업 내용. 필요 인력 등 내용을 적어주세요.'
							defaultValue={text}
							onChange={(e) => {
								setText(e.target.value);
							}}
						/>
					</li>
				</ul>
				<button>문의하기</button>
			</div>
		</div>
	);
};

export default QuestionPage;
