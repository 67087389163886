import React, { useEffect, useRef, useState } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { create } from '../../controller/board';
import { get_company } from '../../controller/company';
import './HomePage.css';
import Title from '../../images/title.svg';
import Title_Mobile from '../../images/title-mobile.svg';
import Title_Mobile_Phone from '../../images/title-mobile-phone.svg';
import Intro1 from '../../images/intro1.png';
import Intro2 from '../../images/intro2.png';
import Intro3 from '../../images/intro3.png';
import Intro4 from '../../images/intro4.png';
import Service1 from '../../images/service1.png';
import Service2 from '../../images/service2.png';
import Service3 from '../../images/service3.png';
import Map from '../../images/map.png';
import Phone from '../../images/phone.svg';
import Check from '../../images/check.png';

const HomePage = () => {
	const history = useHistory();
	const question = useRef();
	const callButton = useRef();
	const questionButton = useRef();
	const [firm, setFirm] = useState('');
	const [address, setAddress] = useState('');
	const [businessNumber, setBusinessNumber] = useState('');
	const [title, setTitle] = useState('');
	const [contact, setContact] = useState('');
	const [text, setText] = useState();
	const introArr = ['생산', '제조', '물류', '용접'];
	const introImgArr = [Intro1, Intro2, Intro3, Intro4];
	const serviceImgArr = [Service1, Service2, Service3];

	const serviceArr = [
		[
			'어떤 일을 할까요?',
			`단순생산보조, 용접, 전기, 전자, 물류, 기계조립,
사상, 물류(3PL), CNC, MCT, 포장, 조립, PCB등
저희는 구인자가 원하는 적합한 인력을 현장에
출력하여 도움을 드릴 전문 인력을 보유하고 있습니다`,
		],
		[
			'상시 인력 모집',
			'제조·생산·용접 등 현장 경험과 기술을\n보유하고 계신 분들을 항상\n기다리고 있습니다',
		],
		[
			'전화 상담',
			'궁금하신 부분, 도움을 드릴 수 있는 부분은\n친절하게 상담해 드리겠습니다',
		],
	];

	useEffect(() => {
		get_company().then((res) => {
			setFirm(res.data.company.name);
			setAddress(res.data.company.address);
			setBusinessNumber(res.data.company.businessNumber);
		});
	}, []);

	const onClick = () => {
		question.current.scrollIntoView({ behavior: 'smooth' });
	};

	const introList = introArr.map((el, idx) => (
		<li className='intro-li' key={idx}>
			<img alt={el} src={introImgArr[idx]} />
			<p>{el}</p>
		</li>
	));

	const serviceList = serviceArr.map((el, idx) => (
		<li className='service-li' key={idx}>
			<img
				className='service-img'
				src={serviceImgArr[idx]}
				alt={idx === 0 ? '서비스' : idx === 1 ? '상시 인력 모집' : '전화 상담'}
			/>
			<div>
				<img className='check' alt='' src={Check} />
				<h4>{el[0]}</h4>
				<p>{el[1]}</p>
				{idx === 2 && <p className='phone'>010-5746-3893</p>}
			</div>
		</li>
	));

	const onSubmit = () => {
		if (title.length === 0) {
			return alert('제목을 입력해주세요');
		} else if (contact.length === 0) {
			return alert('연락처를 입력해주세요');
		} else {
			const Data = {
				title: title.trim(),
				contact: contact.trim(),
				text: text.trim(),
			};
			create(Data).then((res) => {
				if (res.data.success) {
					alert(
						'문의 해주셔서 감사합니다.\n기재해주신 연락처로 빠른 시일 내에 연락드리겠습니다.'
					);
					setTitle('');
					setContact('');
					setText('');
				}
			});
		}
	};

	const followButton = () => {
		const Height = window.scrollY;
		const Width = window.innerWidth;
		if (questionButton.current === undefined) {
			return;
		} else if (Height > 900) {
			if (Width > 1920) {
				questionButton.current.className = 'follow-wide';
			} else if (Width > 760 && Width <= 1920) {
				questionButton.current.className = 'follow-narrow';
			}
		} else {
			questionButton.current.className = 'a';
		}
	};

	window.addEventListener('resize', () => {
		followButton();
	});

	window.addEventListener('scroll', () => {
		followButton();
	});

	return (
		<div className='home'>
			<div className='header'>
				<h2>안산.시화.남동공단.송도</h2>
				<h2>인력공급 전문업체</h2>
				<h2>010-5746-3893</h2>
				<img className='pc_bg' alt='유인 주식회사 업체소개' src={Title} />
				<img
					className='mobile_bg'
					alt='유인 주식회사 업체소개'
					src={Title_Mobile}
					style={{ width: '319px' }}
				/>
				<img
					className='mobile_bg'
					alt='유인 주식회사 업체소개'
					src={Title_Mobile_Phone}
					style={{ width: '348px', height: '48px', top: '325px' }}
				/>
				<div className='title'>
					<h1>(주)</h1>
					<h1>{firm && firm}</h1>
				</div>
				<button className='questionBtn' ref={questionButton} onClick={onClick}>
					문의하기
				</button>
				<button className='callBtn' ref={callButton}>
					<a href='tel:010-5746-3893'>전화문의</a>
				</button>
			</div>

			<div className='intro'>
				<h2>{`안산,시화,반월,화성,남동`}</h2>
				<h2>{`일용직,파견,아웃소싱\n전문업체`}</h2>
				<ul>{introList}</ul>
				<p className='pc-p'>{`다년간의 경험을 바탕으로\n폐사와의 생산을 위한 준비는 모두 되어있습니다`}</p>
				<p className='mobile-p'>
					{`다년간의 경험을\n바탕으로\n폐사와의 생산을 위한\n준비는 모두 되어있습니다`}
				</p>
			</div>

			<div className='contact'>
				<div className='name'>
					<h2>전화로</h2>
					<h2>문의하기</h2>
				</div>
				<div className='number'>
					<img alt='전화' src={Phone} />
					<a href='tel:010-5746-3893'>
						<p>010-5746-3893</p>
					</a>
				</div>

				<div ref={question} className='question'>
					<h2>{`< 문의 >`}</h2>
					<p>
						{`작업에 필요하신 필요 인력 등 문의 주시면\n빠른 시간 내에 연락드리겠습니다.`}
					</p>
					<ul>
						<li>
							<p>제목</p>
							<input
								placeholder='제목을 입력해주세요.'
								value={title}
								onChange={(e) => {
									setTitle(e.target.value);
								}}
							/>
						</li>
						<li>
							<p>연락처</p>
							<input
								placeholder='적어주신 연락처로 연락드리겠습니다.'
								value={contact}
								onChange={(e) => {
									setContact(e.target.value);
								}}
							/>
						</li>
						<li>
							<p>내용</p>
							<textarea
								placeholder='작업 내용. 필요 인력 등 내용을 적어주세요.'
								value={text}
								onChange={(e) => {
									setText(e.target.value);
								}}
							/>
						</li>
					</ul>
					<button onClick={onSubmit}>문의하기</button>
				</div>
			</div>

			<div className='map'>
				<h2>{`안산. 시화.\n남동공단. 송도`}</h2>
				<p className='pc-p'>
					{`반월, 시흥, 마도공단, 남양, 서신, 조암, 수원권선구, 군포시
제조·생산·용접 등 원하시는 적합한 인력을 현장에 출력하여
도움을 드릴 전문인력을 보유하고 있습니다`}
				</p>
				<p className='mobile-p'>
					{`안산, 시화, 남동공단, 송도에서\n인력이 필요하신 분들,\n저희는 제조·생산·용접 등\n원하시는 적합한 인력을\n현장에 출력하여 도움을 드릴\n전문인력을 보유하고 있습니다`}
				</p>
				<img alt='지도' src={Map} />
			</div>

			<div className='service'>
				<ul>{serviceList}</ul>
			</div>
			<button
				className='moveBtn'
				onClick={() => {
					history.push('/apply');
				}}>
				지원
			</button>
			<button
				className='moveBtn'
				onClick={() => {
					history.push('/question');
				}}>
				문의
			</button>
			<div className='footer'>
				<p className='pc-p'>
					{`사업자등록번호 : ${businessNumber} | 연락처 : 010-5746-3893\n주소 : ${address}`}
				</p>
				<p className='mobile-p'>
					{`사업자 등록번호 : ${businessNumber}\n연락처 : 010-5746-3893\n주소 : ${address}`}
				</p>
				<p>ⓒCopyright 2021 - 2021 | All Rights Reserved</p>
			</div>
		</div>
	);
};

export default withRouter(HomePage);
